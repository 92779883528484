import { createContext } from "react";
import * as React from "react";
import { useTranslation } from "react-i18next";

type ContextProps = {
    loading: boolean,
}
export const AuthContextProvider = createContext<ContextProps>({
    loading: true,
});

interface Props {
    children: React.ReactNode;
}
const TranslationProvider = (props: Props) => {
    
    const { ready } = useTranslation();
    
    return (
        <AuthContextProvider.Provider value={{loading: !ready}}>
            {props.children}
        </AuthContextProvider.Provider>
    )
}

export default TranslationProvider
