import {Link, useLocation } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { auth } from "FirebaseSetup/auth";
import PageLoader from "Components/PageLoader";
import { NewPasswordForm } from "Features/Authentication";
import Flex from "Components/BaseComponents/Flex";
import {log} from "../Apis/BaseApi";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

const AuthActions = () => {

    const { t } = useTranslation();

    let query = new URLSearchParams(useLocation().search);
    let mode = query.get('mode');
    let oobCode = query.get('oobCode') ?? '';
    
    const [codeValidated, setCodeValidated] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);

    useEffect(() => {
        if (mode === 'resetPassword' && !codeValidated) {
            auth.verifyPasswordResetCode(oobCode).then(() => {
                setCodeValidated(true);
            }).catch(error => {
                setError('Der skete en fejl, prøv at bede om et nyt link til nulstilling af kodeord');

                let properties: {[key: string]: string} = {};

                 Object.keys(error).map(key => {
                    properties[key] = error[key].toString();
                });

                log(error.message, error.stack, properties).then();
            } )
        }
    }, [mode, oobCode, codeValidated]);
    
    if (!!error) {
        return (
            <Flex style={{height: '100vh'}} $justifyCenter $alignCenter>
                <div style={{width: 550}}>
                    {error}
                </div>
                <StyledLink to="/resetPassword">{t('gotoResetPassword')}</StyledLink>
            </Flex>
        )
    }

    if (!codeValidated) {
        return <PageLoader shown={true}><div></div></PageLoader>
    }

    return (
        <Flex style={{height: '100vh'}} $justifyCenter $alignCenter>
            <div style={{width: 550}}>
                <NewPasswordForm oobCode={oobCode} />
            </div>
        </Flex>
    )
}

const StyledLink = styled(Link)`
    color: ${props => props.theme.colors.primaryText};
`

export default AuthActions
